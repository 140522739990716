import { Box, Radio, RadioGroup, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../configureStore";
import globalStyles from "../../globalStyles";
import { IReason, SET_ISSUE } from "../../redux/reducer/bus";
import Icon from "../atoms/Icon";

interface IssuesParams {
}

const noConsentNeeded: string[] = [
    'NREZ',
];

const consentCodes: string[] = [
    'ACT0406',
    'ACT0408',
    'ACT0610',
    'ACT0612',
];

const Issues = ({
}: IssuesParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const reasons = useSelector<RootState, { [id: string]: IReason; }>(({ bus: { reasons: rs } }) => rs);
    const issue = useSelector<RootState, string | null>(({ bus: { issue: is } }) => is);
    const instCode = useSelector<RootState, string | undefined>(({ bus: { request } }) => request?.institutionCode);

    return (
        <Box sx={globalStyles.box}>
            <RadioGroup value={issue}>
                {Object.keys(reasons).map((reasonId, idx) => (
                    <Box
                        key={reasonId}
                        sx={[
                            globalStyles.ma0,
                            globalStyles.flex,
                            globalStyles.flexCenterStart,
                            globalStyles.borderB,
                        ]}
                    >
                        <Radio
                            id={`reason-${idx}`}
                            sx={[{ paddingTop: '1px', paddingBottom: '2px' }, globalStyles.px1]}
                            value={reasonId}
                            disabled={Boolean(instCode && noConsentNeeded.includes(instCode) && consentCodes.includes(reasonId))}
                            onClick={() => {
                                if (issue === reasonId) dispatch({ type: SET_ISSUE, issue: null });
                                else dispatch({ type: SET_ISSUE, issue: reasonId });
                            }}
                        />
                        <Typography fontSize={12} sx={{ overflowX: 'hidden' }}>{reasons[reasonId].scenario}</Typography>
                        {Boolean(instCode && noConsentNeeded.includes(instCode) && consentCodes.includes(reasonId)) && (
                            <Tooltip title={`${instCode} does not require a consent form`}>
                                <Box sx={[{ alignSelf: 'center', justifySelf: 'center' }, globalStyles.ml1]}>
                                    <Icon
                                        name="InfoIcon"
                                        fontSize="small"
                                        color="primary"
                                    />
                                </Box>
                            </Tooltip>
                        )}
                    </Box>
                ))}
            </RadioGroup>
        </Box>
    );
};

export default Issues;
