/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
import ColorHash from "color-hash";
import { Payload } from ".";
import { AppDispatch } from "../../configureStore";
import { Loan } from "../../interface/loan";
import { Boxes, IBox, Pages, ReturnReviews, UploadedReturn } from "../../interface/returns";
import execute from "../http/execute";
import failure from "../http/failure";
import { get, put } from "../http/request";
import success from "../http/success";

/* eslint-disable default-param-last */
export type ReturnState = {
    returnReviews: ReturnReviews[];
    pdfs: UploadedReturn[];
    pdf?: UploadedReturn;
    loan?: Loan;
    activeBox?: number;
    pdfPages: {
        active: number;
        count: number;
    }
};

const initialState: ReturnState = {
    returnReviews: [],
    pdfs: [],
    pdfPages: {
        active: 0,
        count: 0,
    },
};

export const TOGGLE_USE = 'returns/TOGGLE_USE';
export const TOGGLE_COMPLETED = 'returns/TOGGLE_COMPLETED';

export const UPDATE_PAGE = 'returns/UPDATE_PAGE';
export const UPDATE_BOX = 'returns/UPDATE_BOX';

export const SET_ASSOCIATED_PAGE = 'returns/SET_ASSOCIATED_PAGE';
export const SET_FORM_III_BOXES = 'returns/SET_FORM_III_BOXES';
export const SET_FORM_NAME_BOX = 'returns/SET_FORM_NAME_BOX';
export const SET_FORM_YEAR_BOX = 'returns/SET_FORM_YEAR_BOX';
export const SET_PAGE_SIZE = 'returns/SET_PAGE_SIZE';
export const SET_ACTIVE = 'returns/SET_ACTIVE';
export const SET_BOXES = 'returns/SET_BOXES';
export const SET_PAGES = 'returns/SET_PAGES';
export const SET_PAGE = 'returns/SET_PAGE';
export const SET_FORM = 'returns/SET_FORM';
export const SET_IIIS = 'returns/SET_IIIS';
export const SET_YEAR = 'returns/SET_YEAR';
export const SET_PDF = 'returns/SET_PDF';

export const GET_RETURN_REVIEWS_REQUEST = 'returns/GET_RETURN_REVIEWS_REQUEST';
export const GET_RETURN_REVIEWS_SUCCESS = 'returns/GET_RETURN_REVIEWS_SUCCESS';
export const GET_RETURN_REVIEWS_FAILURE = 'returns/GET_RETURN_REVIEWS_FAILURE';

export const GET_TAX_RETURN_REQUEST = 'returns/GET_TAX_RETURN_REQUEST';
export const GET_TAX_RETURN_SUCCESS = 'returns/GET_TAX_RETURN_SUCCESS';
export const GET_TAX_RETURN_FAILURE = 'returns/GET_TAX_RETURN_FAILURE';

export const SAVE_RETURNS_REQUEST = 'returns/SAVE_RETURNS_REQUEST';
export const SAVE_RETURNS_SUCCESS = 'returns/SAVE_RETURNS_SUCCESS';
export const SAVE_RETURNS_FAILURE = 'returns/SAVE_RETURNS_FAILURE';

type ActionParams =
    | { type: typeof UPDATE_PAGE; pageNumber: number; formName: string; boxes: Boxes; pagePosition: { top: number; left: number; } }
    | { type: typeof GET_TAX_RETURN_SUCCESS; data: { files: { filename: string; base64: string; pages: Pages; inUse: boolean; completed?: boolean; }[]; loan: Loan; }; }
    | { type: typeof SET_BOXES; pageNumber: number; boxes: Boxes; }
    | { type: typeof SET_PAGE_SIZE; boundingRect: DOMRect; }
    | { type: typeof SET_ACTIVE; activeId: number; }
    | { type: typeof SET_FORM; formName: string; }
    | { type: typeof SET_PAGES; count: number; }
    | { type: typeof SET_PDF; pdfName: string; }
    | { type: typeof SET_PAGE; active: number; }
    | { type: typeof SET_IIIS; iiis: string[]; }
    | { type: typeof SET_YEAR; year: string; }
    | { type: typeof UPDATE_BOX; box: IBox; }
    | { type: typeof SET_ASSOCIATED_PAGE; value: string; }
    | { type: typeof SET_FORM_III_BOXES; boxes: Boxes; iiis: string[]; }
    | { type: typeof SET_FORM_NAME_BOX; box?: IBox; }
    | { type: typeof SET_FORM_YEAR_BOX; box?: IBox; }
    | { type: typeof TOGGLE_USE; }
    | { type: typeof TOGGLE_COMPLETED; }
    | { type: typeof GET_RETURN_REVIEWS_SUCCESS; data: ReturnReviews[]; };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case GET_RETURN_REVIEWS_SUCCESS: {
            return {
                ...state,
                returnReviews: action.data,
            };
        }

        case SET_ASSOCIATED_PAGE:
            if (!state.pdf) return state;

            return {
                ...state,
                pdf: {
                    ...state.pdf,
                    pages: {
                        ...state.pdf.pages,
                        [state.pdfPages.active]: {
                            ...state.pdf.pages[state.pdfPages.active],
                            associatedPage: action.value,
                        },
                    },
                },
            };

        case SET_FORM_III_BOXES:
            if (!state.pdf) return state;

            return {
                ...state,
                pdf: {
                    ...state.pdf,
                    pages: {
                        ...state.pdf.pages,
                        [state.pdfPages.active]: {
                            ...state.pdf.pages[state.pdfPages.active],
                            iiiBoxes: action.boxes,
                            iiis: action.iiis,
                        },
                    },
                },
            };

        case SET_FORM_NAME_BOX:
            if (!state.pdf) return state;

            return {
                ...state,
                pdf: {
                    ...state.pdf,
                    pages: {
                        ...state.pdf.pages,
                        [state.pdfPages.active]: {
                            ...state.pdf.pages[state.pdfPages.active],
                            formName: action.box ? state.pdf.pages[state.pdfPages.active].formName : '',
                            formNameBox: action.box,
                        },
                    },
                },
            };

        case SET_FORM_YEAR_BOX:
            if (!state.pdf) return state;

            return {
                ...state,
                pdf: {
                    ...state.pdf,
                    pages: {
                        ...state.pdf.pages,
                        [state.pdfPages.active]: {
                            ...state.pdf.pages[state.pdfPages.active],
                            taxYear: action.box ? state.pdf.pages[state.pdfPages.active].taxYear : undefined,
                            yearBox: action.box,
                        },
                    },
                },
            };

        case TOGGLE_USE:
            if (!state.pdf) return state;

            return {
                ...state,
                pdf: {
                    ...state.pdf,
                    inUse: !state.pdf.inUse,
                },
            };

        case TOGGLE_COMPLETED:
            if (!state.pdf) return state;

            return {
                ...state,
                pdf: {
                    ...state.pdf,
                    completed: !state.pdf.completed,
                },
            };

        case UPDATE_BOX:
            if (!state.pdf) return state;

            return {
                ...state,
                pdf: {
                    ...state.pdf,
                    pages: {
                        ...state.pdf.pages,
                        [state.pdfPages.active]: {
                            ...state.pdf.pages[state.pdfPages.active],
                            boxes: {
                                ...state.pdf.pages[state.pdfPages.active].boxes,
                                [action.box.id]: action.box,
                            },
                        },
                    },
                },
            };

        case SET_ACTIVE:
            return {
                ...state,
                activeBox: action.activeId,
            };

        case SET_YEAR:
            if (!state.pdf) return state;

            return {
                ...state,
                pdf: {
                    ...state.pdf,
                    pages: {
                        ...state.pdf.pages,
                        [state.pdfPages.active]: {
                            ...state.pdf.pages[state.pdfPages.active],
                            yearBox: state.pdf.pages[state.pdfPages.active].yearBox ?? {
                                color: { b: 170, r: 180, g: 0 },
                                id: Date.now(),
                                x: 0,
                                y: 0,
                                width: 48,
                                height: 12,
                            },
                            taxYear: action.year,
                        },
                    },
                },
            };

        case SET_PAGE_SIZE:
            if (!state.pdf) return state;

            return {
                ...state,
                pdf: {
                    ...state.pdf,
                    pages: {
                        ...state.pdf.pages,
                        [state.pdfPages.active]: {
                            ...state.pdf.pages[state.pdfPages.active],
                            pageNumber: state.pdfPages.active,
                            pageLocation: {
                                h: action.boundingRect.height,
                                w: action.boundingRect.width,
                                x: action.boundingRect.x,
                                y: action.boundingRect.y,
                            },
                        },
                    },
                },
            };

        case SET_FORM:
            if (!state.pdf) return state;

            return {
                ...state,
                pdf: {
                    ...state.pdf,
                    pages: {
                        ...state.pdf.pages,
                        [state.pdfPages.active]: {
                            ...state.pdf.pages[state.pdfPages.active],
                            pageNumber: state.pdfPages.active,
                            formName: action.formName,
                            formNameBox: state.pdf.pages[state.pdfPages.active].formNameBox ?? {
                                color: { b: 255, r: 0, g: 0 },
                                id: Date.now(),
                                x: 0,
                                y: 0,
                                width: 48,
                                height: 12,
                            },
                            iiis: [],
                        },
                    },
                },
            };

        case SET_IIIS:
            if (!state.pdf) return state;
            const colorHash = new ColorHash({
                lightness: 0.5,
                hue: { min: 90, max: 270 },
            });

            const byIII = Object.values(state.pdf.pages[state.pdfPages.active].iiiBoxes ?? {}).reduce((acc, curr: IBox) => (curr.iii ? { ...acc, [curr.iii]: curr } : acc), {});
            const now = new Date().getTime();

            return {
                ...state,
                pdf: {
                    ...state.pdf,
                    pages: {
                        ...state.pdf.pages,
                        [state.pdfPages.active]: {
                            ...state.pdf.pages[state.pdfPages.active],
                            iiis: action.iiis,
                            iiiBoxes: action.iiis.reduce((acc, iii, idx) => ({
                                ...acc,
                                [now + idx]: byIII[iii] ? {
                                    ...byIII[iii],
                                    id: now + idx,
                                } : {
                                    color: { r: colorHash.rgb(iii)[0], g: colorHash.rgb(iii)[1], b: colorHash.rgb(iii)[2] },
                                    id: now + idx,
                                    x: 0,
                                    y: 0,
                                    width: 48,
                                    height: 12,
                                    iii,
                                },
                            }), {}),
                        },
                    },
                },
            };

        case SET_PDF:
            const pdfIndex = state.pdfs.findIndex(({ name }) => name === action.pdfName);
            if (pdfIndex === -1) return state;
            const currIdx: number = state.pdfs.findIndex(({ name }) => Boolean(state.pdf?.name && name === state.pdf?.name));

            if (currIdx > -1 && state.pdf && pdfIndex !== currIdx) {
                const newPdfs = structuredClone(state.pdfs);
                newPdfs[currIdx] = structuredClone(state.pdf);
                return {
                    ...state,
                    pdf: newPdfs[pdfIndex],
                    pdfs: newPdfs,
                };
            }
            return {
                ...state,
                pdf: state.pdfs[pdfIndex],
            };

        case SET_BOXES:
            if (!state.pdf) return state;

            return {
                ...state,
                pdf: {
                    ...state.pdf,
                    pages: {
                        ...state.pdf.pages,
                        [action.pageNumber]: {
                            ...state.pdf.pages[action.pageNumber],
                            boxes: action.boxes,
                        },
                    },
                },
            };

        case SET_PAGE:
            return {
                ...state,
                pdfPages: {
                    count: state.pdfPages.count,
                    active: action.active,
                },
            };

        case SET_PAGES:
            return {
                ...state,
                pdfPages: {
                    active: 1,
                    count: action.count,
                },
            };

        case UPDATE_PAGE:
            if (!state.pdf) return state;

            return {
                ...state,
                pdf: {
                    ...state.pdf,
                    pages: {
                        ...state.pdf.pages,
                        [action.pageNumber]: {
                            boxes: action.boxes,
                            formName: action.formName,
                            pageNumber: action.pageNumber,
                            iiis: [],
                            iiiBoxes: [],
                            pageLocation: {
                                x: 0,
                                y: 0,
                                h: 0,
                                w: 0,
                            },
                        },
                    },
                },
            };

        case GET_TAX_RETURN_SUCCESS:
            const pdfs: UploadedReturn[] = action.data.files.map<UploadedReturn>((file) => {
                const byteCharacters = atob(file.base64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob: Blob = new Blob([byteArray], { type: 'application/pdf' });
                // eslint-disable-next-line no-underscore-dangle
                const pdfUrl: string = URL.createObjectURL(blob);
                return {
                    name: file.filename,
                    pages: file.pages,
                    url: pdfUrl,
                    inUse: file.inUse,
                    completed: Boolean(file.completed),
                };
            });

            return {
                ...state,
                pdfs,
                loan: action.data.loan,
            };

        default:
            return state;
    }
}

export const getReturns = (payload: Payload<{ loanNumber: string; fiCode: string; }>) => (dispatch: AppDispatch) => {
    dispatch({
        type: GET_TAX_RETURN_REQUEST,
        payload,
    });

    execute(get, `/returns`, payload.input)
        .then(success(dispatch, GET_TAX_RETURN_SUCCESS, payload))
        .catch(failure(dispatch, GET_TAX_RETURN_FAILURE, payload));
};

export const getReturnReviews = (payload: Payload<any>) => (dispatch: AppDispatch) => {
    dispatch({
        type: GET_RETURN_REVIEWS_REQUEST,
        payload,
    });

    execute(get, `/return-reviews`, payload.input, undefined, payload.overrideAuth)
        .then(success(dispatch, GET_RETURN_REVIEWS_SUCCESS, payload))
        .catch(failure(dispatch, GET_RETURN_REVIEWS_FAILURE, payload));
};

export const saveReturns = (payload: Payload<{ pdfs: UploadedReturn[]; loanNumber: string; fiCode: string; finished: boolean; }>) => (dispatch: AppDispatch) => {
    dispatch({
        type: SAVE_RETURNS_REQUEST,
        payload,
    });

    execute(put, `/returns`, payload.input)
        .then(success(dispatch, SAVE_RETURNS_SUCCESS, payload))
        .catch(failure(dispatch, SAVE_RETURNS_FAILURE, payload));
};

export default reducer;
