import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { IBox, PageLocation } from "../../interface/returns";
import { RootState } from "../../configureStore";

interface BoundingBoxParams {
    box: IBox;
    onResize: (_width: number, _height: number) => void;
    setInside: React.Dispatch<React.SetStateAction<{ x: number; y: number; }>>;
    dragEnd: (_x: number, _y: number) => void;
    onClick: React.MouseEventHandler<HTMLDivElement>;
    onContextMenu: React.MouseEventHandler<HTMLDivElement>;
    onDragStart: React.DragEventHandler<HTMLDivElement>;
}

const BoundingBox = ({
    box,
    onResize,
    setInside,
    dragEnd,
    onClick,
    onContextMenu,
    onDragStart,
}: BoundingBoxParams) => {
    const divRef = useRef<HTMLDivElement | null>(null);
    const pl = useSelector<RootState, PageLocation>(({ returns }) => returns.pdf?.pages[returns?.pdfPages?.active || 0]?.pageLocation || { h: 0, w: 0, x: 0, y: 0 });

    useEffect(() => {
        const handleResize = (entries: ResizeObserverEntry[]) => {
            entries.forEach((entry) => {
                if (Object.values(entry.contentRect.toJSON()).some(Boolean)) {
                    const { width, height } = entry.contentRect;
                    onResize(width, height);
                }
            });
        };

        const resizeObserver = new ResizeObserver(handleResize);

        if (divRef.current) {
            resizeObserver.observe(divRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <div
            ref={divRef}
            key={box.id}
            onContextMenu={onContextMenu}
            onClick={onClick}
            style={{
                zIndex: 100,
                position: 'absolute',
                left: box.x + pl.x,
                top: box.y + pl.y,
                width: box.width,
                height: box.height,
                border: `2px solid rgb(${box.color.r}, ${box.color.g}, ${box.color.b})`,
                backgroundColor: `rgba(${box.color.r}, ${box.color.g}, ${box.color.b}, 0.1)`,
                resize: 'both',
                overflow: 'auto',
                cursor: 'move',
            }}
            draggable
            onDragStart={(e) => {
                setInside({ x: e.clientX - box.x, y: e.clientY - box.y });
                (e.target as any).classList.add('hide');
                onDragStart(e);
            }}
            onDragEnd={(e) => {
                e.preventDefault();
                (e.target as any).classList.remove('hide');
                dragEnd(e.clientX, e.clientY);
            }}
        />
    );
};

export default BoundingBox;
