import { Box } from "@mui/material";
import globalStyles from "../../globalStyles";

interface AppHeaderReturnsParams {
}

const AppHeaderReturns = ({
}: AppHeaderReturnsParams) => (
    <Box sx={[globalStyles.flexCenterBetween, globalStyles.pa1]}>
        <Box sx={globalStyles.flexCenterBetween}>
            <Box
                component="img"
                sx={{
                    // height: 40,
                    width: 170,
                }}
                alt="Halcyon Still Water"
                src="./logo.png"
            />
        </Box>
    </Box>
);

export default AppHeaderReturns;
