import { InteractionType } from "@azure/msal-browser";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Buffer } from 'buffer';
import { v4 } from "uuid";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { useEffect, useMemo, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "./configureStore";
import { SET_MOBILE, clearError, clearSuccessful } from "./redux/reducer/status";

import CheckOperations from "./components/templates/CheckOperations";
import TaxReturns from "./components/templates/TaxReturns";

interface OperationsAppParams {
}

const Pages = ({ id }: { id: string }) => (
    <BrowserRouter>
        <Routes>
            <Route index element={<CheckOperations id={id} />} />
            <Route path="returns" element={<TaxReturns />} />
        </Routes>
    </BrowserRouter>
);

const OperationsApp = ({
}: OperationsAppParams) => {
    const request = {
        scopes: [`api://${process.env.REACT_APP_TOKEN_AUDIENCE}/api`],
    };

    const dispatch = useDispatch<AppDispatch>();
    const error = useSelector<RootState, string | undefined>(({ status }) => status.error);
    const successful = useSelector<RootState, string | undefined>(({ status }) => status.successful);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        dispatch({ type: SET_MOBILE, isMobile: window.innerWidth <= 768 });
    }, []);

    const handleWindowSizeChange = () => {
        dispatch({ type: SET_MOBILE, isMobile: window.innerWidth <= 768 });
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (error || successful) setOpen(true);
    }, [error, successful]);

    const { result } = useMsalAuthentication(InteractionType.Redirect, request);

    const id = useMemo<string>(() => {
        const tabId = sessionStorage.getItem('tabId');
        if (tabId) return tabId;

        const newTabId = v4();
        sessionStorage.setItem('tabId', newTabId);
        return newTabId;
    }, []);

    useEffect(() => {
        if (result) {
            const { accessToken, idToken } = result;

            localStorage.setItem('ACCESS_TOKEN', accessToken);
            localStorage.setItem('ID_TOKEN', idToken);
            window.dispatchEvent(new Event("token"));
            try {
                localStorage.setItem('ACCESS_EXPIRY', (JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii')).exp * 1000).toString());
            } catch (err) {
                console.error(err);
            }
        }
    }, [result]);

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={((_e) => { dispatch(clearError()); dispatch(clearSuccessful()); setOpen(false); })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert severity={successful ? "success" : "error"} color={successful ? "success" : "error"}>
                    {successful || error}
                </Alert>
            </Snackbar>
            <AuthenticatedTemplate>
                <Pages id={id} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate />
        </>
    );
};

export default OperationsApp;
