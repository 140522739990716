/* eslint-disable import/no-cycle */
import { SUCCESSFUL_MESSAGE } from '../reducer/status';

/* eslint-disable no-param-reassign */
const success = (dispatch: any, type: string, input: any, message?: string) => (payload: any) => {
    if (payload.data && payload.data.isSuccess === false) {
        throw new Error(JSON.stringify(payload));
    }
    try {
        if (input?.onSuccess) input.onSuccess(payload);
    } catch (err) {
        console.error(err);
        /* empty */
    }

    if (message) {
        dispatch({
            type: SUCCESSFUL_MESSAGE,
            message,
        });
    }

    dispatch({
        type,
        ...input,
        data: payload.data,
    });
};

export default success;
