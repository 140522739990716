const globalStyles = {
    col: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    growBetween: {
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
    },
    justifyBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    justifyAround: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    flexCenterCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexCenterStart: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    flexCenterEnd: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    flexCenterAround: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    flexCenterBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    flexStartCenter: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    flexStartStart: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    flexStartEnd: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
    flexStartAround: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
    },
    flexStartBetween: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    flexEndCenter: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    flexEndStart: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
    },
    flexEndEnd: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    flexEndAround: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-around',
    },
    flexEndBetween: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    zn1: {
        zIndex: -100,
    },
    z0: {
        zIndex: 0,
    },
    z1: {
        zIndex: 100,
    },
    z2: {
        zIndex: 200,
    },
    z3: {
        zIndex: 300,
    },
    z4: {
        zIndex: 400,
    },
    z5: {
        zIndex: 500,
    },
    z6: {
        zIndex: 600,
    },
    z7: {
        zIndex: 700,
    },
    z8: {
        zIndex: 800,
    },
    z9: {
        zIndex: 900,
    },
    z10: {
        zIndex: 1000,
    },
    z11: {
        zIndex: 1100,
    },
    w25: {
        width: '25%',
    },
    w100: {
        width: '100%',
    },
    w80: {
        width: '80%',
    },
    h300px: {
        height: 300,
    },
    borderR: {
        borderRight: 'solid #F0F2F5 1px',
    },
    border: {
        border: 'solid #F0F2F5 1px',
    },
    borderB: {
        borderBottom: 'solid #F0F2F5 1px',
    },
    mr1: {
        marginRight: 1,
    },
    mr2: {
        marginRight: 2,
    },
    mr3: {
        marginRight: 3,
    },
    mr4: {
        marginRight: 4,
    },
    mr5: {
        marginRight: 5,
    },
    mr6: {
        marginRight: 6,
    },
    mr7: {
        marginRight: 7,
    },
    mr8: {
        marginRight: 8,
    },
    mb0: {
        marginBottom: 0,
    },
    mb1: {
        marginBottom: 1,
    },
    mb2: {
        marginBottom: 2,
    },
    mb3: {
        marginBottom: 3,
    },
    mb4: {
        marginBottom: 4,
    },
    mb5: {
        marginBottom: 5,
    },
    mb6: {
        marginBottom: 6,
    },
    mb7: {
        marginBottom: 7,
    },
    mb8: {
        marginBottom: 8,
    },
    mt1: {
        marginTop: 1,
    },
    mt2: {
        marginTop: 2,
    },
    mt3: {
        marginTop: 3,
    },
    mt4: {
        marginTop: 4,
    },
    mt5: {
        marginTop: 5,
    },
    mt6: {
        marginTop: 6,
    },
    mt7: {
        marginTop: 7,
    },
    mt8: {
        marginTop: 8,
    },
    ml1: {
        marginLeft: 1,
    },
    ml2: {
        marginLeft: 2,
    },
    ml3: {
        marginLeft: 3,
    },
    ml4: {
        marginLeft: 4,
    },
    ml5: {
        marginLeft: 5,
    },
    ml6: {
        marginLeft: 6,
    },
    ml7: {
        marginLeft: 7,
    },
    ml8: {
        marginLeft: 8,
    },
    mx1: {
        marginRight: 1,
        marginLeft: 1,
    },
    mx2: {
        marginRight: 2,
        marginLeft: 2,
    },
    mx3: {
        marginRight: 3,
        marginLeft: 3,
    },
    mx4: {
        marginRight: 4,
        marginLeft: 4,
    },
    mx5: {
        marginRight: 5,
        marginLeft: 5,
    },
    mx6: {
        marginRight: 6,
        marginLeft: 6,
    },
    mx7: {
        marginRight: 7,
        marginLeft: 7,
    },
    mx8: {
        marginRight: 8,
        marginLeft: 8,
    },
    my0: {
        marginTop: 0,
        marginBottom: 0,
    },
    my1: {
        marginTop: 1,
        marginBottom: 1,
    },
    my2: {
        marginTop: 2,
        marginBottom: 2,
    },
    my3: {
        marginTop: 3,
        marginBottom: 3,
    },
    my4: {
        marginTop: 4,
        marginBottom: 4,
    },
    my5: {
        marginTop: 5,
        marginBottom: 5,
    },
    my6: {
        marginTop: 6,
        marginBottom: 6,
    },
    my7: {
        marginTop: 7,
        marginBottom: 7,
    },
    my8: {
        marginTop: 8,
        marginBottom: 8,
    },
    ma0: {
        margin: 0,
    },
    ma1: {
        margin: 1,
    },
    ma2: {
        margin: 2,
    },
    ma3: {
        margin: 3,
    },
    ma4: {
        margin: 4,
    },
    ma5: {
        margin: 5,
    },
    ma6: {
        margin: 6,
    },
    ma7: {
        margin: 7,
    },
    ma8: {
        margin: 8,
    },
    pr1: {
        paddingRight: 1,
    },
    pr2: {
        paddingRight: 2,
    },
    pr3: {
        paddingRight: 3,
    },
    pr4: {
        paddingRight: 4,
    },
    pr5: {
        paddingRight: 5,
    },
    pr6: {
        paddingRight: 6,
    },
    pr7: {
        paddingRight: 7,
    },
    pr8: {
        paddingRight: 8,
    },
    pb0: {
        paddingBottom: 0,
    },
    pb1: {
        paddingBottom: 1,
    },
    pb2: {
        paddingBottom: 2,
    },
    pb3: {
        paddingBottom: 3,
    },
    pb4: {
        paddingBottom: 4,
    },
    pb5: {
        paddingBottom: 5,
    },
    pb6: {
        paddingBottom: 6,
    },
    pb7: {
        paddingBottom: 7,
    },
    pb8: {
        paddingBottom: 8,
    },
    pt0: {
        paddingTop: 0,
    },
    pt1: {
        paddingTop: 1,
    },
    pt2: {
        paddingTop: 2,
    },
    pt3: {
        paddingTop: 3,
    },
    pt4: {
        paddingTop: 4,
    },
    pt5: {
        paddingTop: 5,
    },
    pt6: {
        paddingTop: 6,
    },
    pt7: {
        paddingTop: 7,
    },
    pt8: {
        paddingTop: 8,
    },
    pl1: {
        paddingLeft: 1,
    },
    pl2: {
        paddingLeft: 2,
    },
    pl3: {
        paddingLeft: 3,
    },
    pl4: {
        paddingLeft: 4,
    },
    pl5: {
        paddingLeft: 5,
    },
    pl6: {
        paddingLeft: 6,
    },
    pl7: {
        paddingLeft: 7,
    },
    pl8: {
        paddingLeft: 8,
    },
    px0: {
        paddingRight: 0,
        paddingLeft: 0,
    },
    px1: {
        paddingRight: 1,
        paddingLeft: 1,
    },
    px2: {
        paddingRight: 2,
        paddingLeft: 2,
    },
    px3: {
        paddingRight: 3,
        paddingLeft: 3,
    },
    px4: {
        paddingRight: 4,
        paddingLeft: 4,
    },
    px5: {
        paddingRight: 5,
        paddingLeft: 5,
    },
    px6: {
        paddingRight: 6,
        paddingLeft: 6,
    },
    px7: {
        paddingRight: 7,
        paddingLeft: 7,
    },
    px8: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    py0: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    py1: {
        paddingTop: 1,
        paddingBottom: 1,
    },
    py2: {
        paddingTop: 2,
        paddingBottom: 2,
    },
    py3: {
        paddingTop: 3,
        paddingBottom: 3,
    },
    py4: {
        paddingTop: 4,
        paddingBottom: 4,
    },
    py5: {
        paddingTop: 5,
        paddingBottom: 5,
    },
    py6: {
        paddingTop: 6,
        paddingBottom: 6,
    },
    py7: {
        paddingTop: 7,
        paddingBottom: 7,
    },
    py8: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    pa0: {
        padding: 0,
    },
    pa1: {
        padding: 1,
    },
    pa2: {
        padding: 2,
    },
    pa3: {
        padding: 3,
    },
    pa4: {
        padding: 4,
    },
    pa5: {
        padding: 5,
    },
    pa6: {
        padding: 6,
    },
    pa7: {
        padding: 7,
    },
    pa8: {
        padding: 8,
    },
    mxN4: {
        marginLeft: -16,
        marginRight: -16,
    },
    brSm: {
        borderRadius: 4,
    },
    brXs: {
        borderRadius: 2,
    },
    brMd: {
        borderRadius: 16,
    },
    brMdL: {
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
    },
    brMdR: {
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16,
    },
    brFull: {
        borderRadius: '100%',
    },
    truncated: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    whiteText: {
        color: '#FFFFFF',
    },
    white: {
        backgroundColor: '#FFFFFF',
    },
    blackText: {
        color: '#000000',
    },
    black: {
        backgroundColor: '#000000',
    },
    backgroundText: {
        color: '#F0F2F5',
    },
    background: {
        backgroundColor: '#F0F2F5',
    },
    dangerText: {
        color: '#F05379',
    },
    danger: {
        backgroundColor: '#F05379',
    },
    darkBlueText: {
        color: '#055FFC',
    },
    darkBlue: {
        backgroundColor: '#055FFC',
    },
    mediumBlueText: {
        color: '#3366CC',
    },
    mediumBlue: {
        backgroundColor: '#3366CC',
    },
    lightBlueText: {
        color: '#0099FF',
    },
    lightBlue: {
        backgroundColor: '#0099FF',
    },
    successText: {
        color: '#4C9A2A',
    },
    success: {
        backgroundColor: '#4C9A2A',
    },
    yellowText: {
        color: '#FFD73B',
    },
    yellow: {
        backgroundColor: '#FFD73B',
    },
    darkGreenText: {
        color: '#009280',
    },
    darkGreen: {
        backgroundColor: '#009280',
    },
    lightGreenText: {
        color: '#F2F9F8',
    },
    lightGreen: {
        backgroundColor: '#F2F9F8',
    },
    lightBackgroundText: {
        color: '#f8fafc',
    },
    lightBackground: {
        backgroundColor: '#f8fafc',
    },
    lightGrayText: {
        color: '#D3D3D3',
    },
    lightGray: {
        backgroundColor: '#D3D3D3',
    },
    darkBackgroundText: {
        color: '#E9E9E9',
    },
    darkBackground: {
        backgroundColor: '#E9E9E9',
    },
    box: {
        backgroundColor: '#FFFFFF',
        border: 'solid #F0F2F5 1px',
        padding: 1,
        borderRadius: 2,
    },
    pointer: {
        cursor: 'pointer',
    },
};

export default globalStyles;
