import {
    Autocomplete,
    Box,
    Card,
    CardContent,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid2 as Grid,
    Input,
    InputLabel,
    TextField,
    Typography,
} from "@mui/material";
import ColorHash from "color-hash";
import { useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Icon from "../atoms/Icon";
import { AppDispatch, RootState } from "../../configureStore";
import { SET_ACTIVE, SET_BOXES, SET_PAGE, UPDATE_BOX } from "../../redux/reducer/returns";
import globalStyles from "../../globalStyles";
import { Boxes, IBox, Page } from "../../interface/returns";
import { taxForms } from "../../utils/returns";
import Button from "../atoms/Button";
import { randomColor } from "../../utils";
import { Loan } from "../../interface/loan";

interface ActiveBoxParams {
}

const ActiveBox = ({
}: ActiveBoxParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const colorHash = new ColorHash({
        lightness: 0.5,
        hue: { min: 90, max: 270 },
    });

    const buttonRef = useRef<any>(null);
    const pdfPages = useSelector<RootState, { active: number; count: number; }>(({ returns: { pdfPages: pp } }) => pp);
    const page = useSelector<RootState, Page | undefined>(({ returns: { pdfPages: { active: a }, pdf } }) => pdf?.pages?.[a]);
    const activeBox = useSelector<RootState, IBox | undefined>(({ returns: { activeBox: ab, pdf: p, pdfPages: { active: a } } }) => (ab ? p?.pages[a]?.boxes?.[ab] : undefined));
    const boxes = useSelector<RootState, Boxes>(({ returns: { pdf: p, pdfPages: { active: a } } }) => p?.pages[a]?.boxes ?? []);
    const loan = useSelector<RootState, Loan | undefined>(({ returns }) => returns.loan);

    const used = useMemo<Set<string | undefined>>(() => new Set(Object.values(boxes).map(({ fieldName }: IBox) => fieldName?.label)), [boxes, activeBox]);

    const updateActiveBox = (box: IBox) => { dispatch({ type: UPDATE_BOX, box }); };
    const setActiveBox = (activeId?: number) => { dispatch({ type: SET_ACTIVE, activeId }); };
    const setBoxes = useCallback((newBoxes: Boxes) => { dispatch({ type: SET_BOXES, boxes: newBoxes, pageNumber: pdfPages.active }); }, [pdfPages.active]);

    const newBoxClick = useCallback(() => {
        if (page) {
            const newBox: IBox = {
                id: Date.now(),
                x: (buttonRef.current?.firstChild?.offsetLeft || page.pageLocation.x) - page.pageLocation.x - 56,
                y: (buttonRef.current?.firstChild?.offsetTop || page.pageLocation.y) - page.pageLocation.y + 12,
                width: 48,
                height: 12,
                color: randomColor(),
            };
            setBoxes({
                ...boxes,
                [newBox.id]: newBox,
            });
            setActiveBox(newBox.id);
        }
    }, [buttonRef, page]);

    return (
        <Box sx={globalStyles.col}>
            <Icon
                name="ArrowRight"
                color={pdfPages.active === pdfPages.count ? "disabled" : "primary"}
                sx={{
                    height: 64,
                    width: 64,
                }}
                iconButton={{
                    id: 'go-right',
                    disabled: pdfPages.active === pdfPages.count,
                }}
                onClick={() => {
                    window.scrollTo({ top: 0 });
                    if (pdfPages.active !== pdfPages.count) {
                        dispatch({ type: SET_PAGE, active: pdfPages.active + 1 });
                    }
                }}
            />
            <Grid
                key={activeBox?.id || ''}
                container
                spacing={2}
                sx={[
                    {
                        border: activeBox ? `2px solid rgb(${activeBox.color.r}, ${activeBox.color.g}, ${activeBox.color.b})` : undefined,
                        backgroundColor: activeBox ? `rgba(${activeBox.color.r}, ${activeBox.color.g}, ${activeBox.color.b}, 0.1)` : undefined,
                        borderRadius: '4px',
                    },
                    globalStyles.pa1,
                ]}
            >
                <Grid size={12}>
                    <Autocomplete
                        disabled={!activeBox}
                        disablePortal
                        options={taxForms?.[page?.formName || '']?.fields ?? []}
                        value={activeBox?.fieldName || { key: '', label: '' }}
                        onChange={(_, value) => {
                            if (activeBox) {
                                updateActiveBox({ ...activeBox, fieldName: value || undefined });
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Field Type"
                            />
                        )}
                    />
                </Grid>
                {page?.formName === 'Attachment' && (
                    <>
                        <Grid size={12}>
                            <Autocomplete
                                disabled={!activeBox}
                                disablePortal
                                options={activeBox?.fieldName?.forms ?? []}
                                value={activeBox?.form || ''}
                                onChange={(_, value) => {
                                    if (activeBox) {
                                        updateActiveBox({ ...activeBox, form: value || undefined });
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Form Type"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid size={12}>
                            <FormGroup>
                                {([...loan?.borrowers ?? [], ...loan?.businesses ?? []] as any[]).map(({ firstName, lastName, name, irsIncomeId }: any) => (
                                    <FormControlLabel
                                        key={`check-${irsIncomeId}`}
                                        control={(
                                            <Checkbox
                                                style={{
                                                    color: colorHash.hex(irsIncomeId),
                                                }}
                                                checked={(activeBox?.iiis ?? [])?.includes(irsIncomeId)}
                                                disabled={!activeBox?.form}
                                                onChange={(e) => {
                                                    if (activeBox) {
                                                        if (e.target.checked) {
                                                            updateActiveBox({ ...activeBox, iiis: [...(activeBox?.iiis ?? []), irsIncomeId] });
                                                        } else {
                                                            updateActiveBox({ ...activeBox, iiis: (activeBox?.iiis ?? []).filter((iii) => iii !== irsIncomeId) });
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                        label={(<Typography style={{ color: colorHash.hex(irsIncomeId) }}>{firstName ? `${firstName} ${lastName}` : name}</Typography>)}
                                    />
                                ))}
                            </FormGroup>
                        </Grid>
                    </>
                )}
                <Grid size={12}>
                    <FormControl fullWidth>
                        <InputLabel id="value-input-label">Field Value</InputLabel>
                        <Input
                            disabled={!activeBox}
                            value={activeBox?.value || ''}
                            onChange={(e) => {
                                if (activeBox) {
                                    updateActiveBox({ ...activeBox, value: e.target.value || undefined });
                                }
                            }}
                            placeholder="Field Value"
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid sx={[globalStyles.my2, globalStyles.flexCenterCenter]}>
                <Grid
                    ref={buttonRef}
                    size={12}
                >
                    <Button
                        variant="contained"
                        onClick={newBoxClick}
                    >
                        New box
                    </Button>
                </Grid>
            </Grid>
            <Card elevation={1} title="Options" id={`${page?.pageNumber}-options`}>
                <CardContent sx={globalStyles.pb0}><Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>Options</Typography></CardContent>
                <CardContent sx={[globalStyles.col, { maxHeight: 200, overflowY: 'scroll' }]}>
                    {taxForms?.[page?.formName || '']?.fields ? (taxForms[page?.formName || ''].fields).map((v, idx) => (
                        <Box key={`${page?.pageNumber}-${page?.formName}-${v.label}-add-button`} id={`${page?.pageNumber}-${page?.formName}-${v.label}-add-button`}>
                            {Boolean(idx) && <Divider />}
                            <Button
                                size="small"
                                color="info"
                                disabled={used.has(v.label) && page?.formName !== 'Attachment'}
                                onClick={() => {
                                    const el = document.getElementById(`${page?.pageNumber}-options`);
                                    if (page && el) {
                                        const newBox: IBox = {
                                            id: Date.now(),
                                            x: ((el?.firstChild as any)?.offsetLeft || page.pageLocation.x) - page.pageLocation.x - 56,
                                            y: ((el?.firstChild as any)?.offsetTop || page.pageLocation.y) - page.pageLocation.y + 12,
                                            width: 48,
                                            height: 12,
                                            color: randomColor(),
                                            fieldName: v,
                                        };
                                        setBoxes({
                                            ...boxes,
                                            [newBox.id]: newBox,
                                        });
                                        setActiveBox(newBox.id);
                                    }
                                }}
                            >
                                {v.label}
                            </Button>
                        </Box>
                    )) : (<Typography>Select a Form Name</Typography>)}
                </CardContent>
            </Card>
        </Box>
    );
};

export default ActiveBox;
