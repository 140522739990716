// eslint-disable-next-line import/no-cycle
import { ERROR_MESSAGE } from '../reducer/status';

/* eslint-disable no-param-reassign */
const failure = (dispatch: any, type: string, input: any, message?: string) => (payload: any) => {
    try {
        let parsed: any = {};
        try {
            parsed = JSON.parse(payload.message);
        } catch {
            parsed = payload.response;
        }
        dispatch({
            type,
            ...input,
            data: parsed?.data,
        });

        dispatch({
            type: ERROR_MESSAGE,
            message: parsed?.data?.responseData ?? message ?? 'Something went wrong!',
        });

        if (input?.onFailure) input.onFailure(parsed);
        if (input?.onError) input.onError(parsed);
    } catch (e) {
        dispatch({
            type,
            ...input,
            data: payload?.data,
        });

        dispatch({
            type: ERROR_MESSAGE,
            message: message ?? 'Something went wrong!',
        });

        if (input?.onFailure) input.onFailure(payload);
        if (input?.onError) input.onError(payload);
    }
};

export default failure;
