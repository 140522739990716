import { useCallback, useMemo, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { DocumentCallback, OnDocumentLoadSuccess } from "react-pdf/dist/cjs/shared/types";
import {
    Box,
    Checkbox,
    FormControlLabel,
    Paper,
    Typography,
} from "@mui/material";

import globalStyles from "../../globalStyles";
import PageInfo from "../organisms/PageInfo";
import ActiveBox from "../organisms/ActiveBox";
import { AppDispatch, RootState } from "../../configureStore";
import { SET_ACTIVE, SET_BOXES, SET_FORM_III_BOXES, SET_FORM_NAME_BOX, SET_FORM_YEAR_BOX, SET_PAGE_SIZE, SET_PAGES, TOGGLE_COMPLETED, TOGGLE_USE } from "../../redux/reducer/returns";
import Loading from "../Loading";
import { Boxes, IBox, Page as IPage, Pages, UploadedReturn } from "../../interface/returns";
import BoundingBox from "../molecules/BoundingBox";
import { randomColor } from "../../utils";

interface TaxReturnParams { }

const TaxReturn = ({ }: TaxReturnParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const pdfPages = useSelector<RootState, { active: number; count: number; }>(({ returns: { pdfPages: pp } }) => pp);
    const pdf = useSelector<RootState, UploadedReturn | undefined>(({ returns }) => returns.pdf);
    const boxes = useSelector<RootState, Boxes>(({ returns: { pdf: p, pdfPages: { active: a } } }) => p?.pages[a]?.boxes ?? []);
    const pages = useSelector<RootState, Pages>(({ returns }) => returns.pdf?.pages || {});

    const page = useMemo<IPage>(() => pages[pdfPages.active] || { pageLocation: { h: 0, w: 0, x: 0, y: 0 } }, [pdfPages.active, pages]);

    const ref = useRef<HTMLDivElement>(null);

    const [inside, setInside] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [dragging, setDragging] = useState<number | null>(null);

    const onDocumentLoadSuccess: OnDocumentLoadSuccess = (document: DocumentCallback): void => {
        const { numPages: numPage } = document;
        dispatch({ type: SET_PAGES, count: numPage });
    };

    const setFormIIIBoxes = (iiiBoxes: Boxes) => { dispatch({ type: SET_FORM_III_BOXES, boxes: iiiBoxes, iiis: Object.values(iiiBoxes).map(({ iii }) => iii) }); };
    const setFormYearBox = (box?: IBox) => { dispatch({ type: SET_FORM_YEAR_BOX, box }); };
    const setFormNameBox = (box?: IBox) => { dispatch({ type: SET_FORM_NAME_BOX, box }); };

    const setActiveBox = (activeId?: number) => { dispatch({ type: SET_ACTIVE, activeId }); };
    const setBoxes = useCallback((newBoxes: Boxes) => { dispatch({ type: SET_BOXES, boxes: newBoxes, pageNumber: pdfPages.active }); }, [pdfPages.active]);

    const handleRightClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        const { pageX: x, pageY: y } = event;

        const newBox: IBox = {
            id: Date.now(),
            x: x - 24 - page.pageLocation.x,
            y: y - 6 - page.pageLocation.y,
            width: 48,
            height: 12,
            color: randomColor(),
        };
        setBoxes({
            ...boxes,
            [newBox.id]: newBox,
        });
        setActiveBox(newBox.id);
    }, [pdfPages, boxes, page.pageLocation]);

    return pdf?.url ? (
        <Paper sx={[{ width: '100%' }, globalStyles.pt2, globalStyles.pb1]}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h5">{pdf.name}</Typography>
                <Box sx={[{ textAlign: 'center' }, globalStyles.flexCenterCenter]}>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={pdf.completed}
                                onChange={() => { dispatch({ type: TOGGLE_COMPLETED }); }}
                            />
                        )}
                        label="Completed"
                    />
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={pdf.inUse}
                                onChange={() => { dispatch({ type: TOGGLE_USE }); }}
                            />
                        )}
                        label="Use Document"
                    />
                </Box>
            </Box>
            <Box sx={globalStyles.flexStartAround}>
                <Box width="22%" sx={globalStyles.flexCenterEnd}><PageInfo /></Box>
                <Box width="52%">
                    <Document
                        file={pdf.url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        renderMode="canvas"
                        className="flex-center-center col"
                        onError={(err) => {
                            console.error(err);
                        }}
                    >
                        <div style={{ border: '1px solid lightgray' }} ref={ref} id="page-found">
                            <Page
                                onContextMenu={handleRightClick}
                                onLoadSuccess={() => {
                                    const el = document.getElementById('page-found');
                                    if (el) dispatch({ type: SET_PAGE_SIZE, boundingRect: el.getBoundingClientRect() });
                                }}
                                pageNumber={pdfPages.active || undefined}
                            />
                        </div>
                        {page.formNameBox && (
                            <BoundingBox
                                onClick={() => { }}
                                box={page.formNameBox}
                                dragEnd={(x, y) => {
                                    if (dragging === page?.formNameBox?.id) {
                                        setDragging(null);
                                        const newBox = structuredClone(page.formNameBox);
                                        newBox.x = x - inside.x;
                                        newBox.y = y - inside.y;
                                        const newDate = new Date().getTime();
                                        newBox.id = newDate;
                                        setFormNameBox(newBox);
                                    }
                                }}
                                onContextMenu={(e) => { e.preventDefault(); setFormNameBox(); }}
                                onDragStart={() => { setDragging(page?.formNameBox?.id || null); }}
                                onResize={(w, h) => {
                                    if (dragging !== page.formNameBox?.id) {
                                        const newBox = structuredClone(page.formNameBox);
                                        if (newBox) {
                                            newBox.height = h;
                                            newBox.width = w;
                                            setFormNameBox(newBox);
                                        }
                                    }
                                }}
                                setInside={setInside}
                                key={`formname-box-${page.formNameBox.id}`}
                            />
                        )}
                        {page.yearBox && (
                            <BoundingBox
                                onClick={() => { }}
                                box={page.yearBox}
                                dragEnd={(x, y) => {
                                    if (dragging === page?.yearBox?.id) {
                                        setDragging(null);
                                        const newBox = structuredClone(page.yearBox);
                                        newBox.x = x - inside.x;
                                        newBox.y = y - inside.y;
                                        const newDate = new Date().getTime();
                                        newBox.id = newDate;
                                        setFormYearBox(newBox);
                                    }
                                }}
                                onContextMenu={(e) => { e.preventDefault(); setFormYearBox(); }}
                                onDragStart={() => { setDragging(page?.yearBox?.id || null); }}
                                onResize={(w, h) => {
                                    if (dragging !== page.yearBox?.id) {
                                        const newBox = structuredClone(page.yearBox);
                                        if (newBox) {
                                            newBox.height = h;
                                            newBox.width = w;
                                            setFormYearBox(newBox);
                                        }
                                    }
                                }}
                                setInside={setInside}
                                key={`yearBox-box-${page.yearBox.id}`}
                            />
                        )}
                        {Object.keys(page.iiiBoxes || {}).map((boxId) => (
                            <BoundingBox
                                onClick={() => { }}
                                box={page.iiiBoxes[Number(boxId)]}
                                onContextMenu={(e) => {
                                    e.preventDefault();
                                    const newBoxes = structuredClone(page.iiiBoxes);
                                    delete newBoxes[Number(boxId)];
                                    setFormIIIBoxes(newBoxes);
                                }}
                                dragEnd={(x, y) => {
                                    if (dragging === Number(boxId)) {
                                        setDragging(null);
                                        const newBoxes = structuredClone(page.iiiBoxes);
                                        newBoxes[Number(boxId)].x = x - inside.x;
                                        newBoxes[Number(boxId)].y = y - inside.y;
                                        const newDate = new Date().getTime();
                                        newBoxes[newDate] = {
                                            ...structuredClone(newBoxes[Number(boxId)]),
                                            id: newDate,
                                        };
                                        delete newBoxes[Number(boxId)];
                                        setFormIIIBoxes(newBoxes);
                                    }
                                }}
                                onDragStart={() => { setDragging(Number(boxId)); }}
                                onResize={(w, h) => {
                                    if (dragging !== Number(boxId)) {
                                        const newBoxes = structuredClone(page.iiiBoxes);
                                        newBoxes[Number(boxId)].height = h;
                                        newBoxes[Number(boxId)].width = w;
                                        setFormIIIBoxes(newBoxes);
                                    }
                                }}
                                setInside={setInside}
                                key={`iii_box_${boxId}`}
                            />
                        ))}
                        {Object.keys(boxes).map((boxId, boxIdx) => (
                            <BoundingBox
                                key={`box_${boxId}`}
                                onClick={() => { setActiveBox(Number(boxId)); }}
                                onContextMenu={(e) => {
                                    e.preventDefault();
                                    const newBoxes = structuredClone(boxes);
                                    delete newBoxes[Number(boxId)];
                                    setBoxes(newBoxes);
                                    setActiveBox(Object.keys(newBoxes || {})?.[0] ? Number(Object.keys(newBoxes)[0]) : undefined);
                                }}
                                box={boxes[Number(boxId)]}
                                onResize={(w, h) => {
                                    if (dragging !== boxIdx) {
                                        const newBoxes = structuredClone(boxes);
                                        newBoxes[Number(boxId)].height = h;
                                        newBoxes[Number(boxId)].width = w;
                                        setBoxes(newBoxes);
                                    }
                                }}
                                dragEnd={(x, y) => {
                                    if (dragging === boxIdx) {
                                        setDragging(null);
                                        const newBoxes = structuredClone(boxes);
                                        newBoxes[Number(boxId)].x = x - inside.x;
                                        newBoxes[Number(boxId)].y = y - inside.y;
                                        const newDate = new Date().getTime();
                                        newBoxes[newDate] = {
                                            ...structuredClone(newBoxes[Number(boxId)]),
                                            id: newDate,
                                        };
                                        delete newBoxes[Number(boxId)];
                                        setBoxes(newBoxes);
                                        setActiveBox(newDate);
                                    }
                                }}
                                onDragStart={() => { setDragging(boxIdx); }}
                                setInside={setInside}
                            />
                        ))}
                    </Document>
                </Box>
                <Box width="22%"><ActiveBox /></Box>
            </Box>
        </Paper>
    ) : (
        <Loading />
    );
};

export default TaxReturn;
